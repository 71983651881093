import React from 'react';
import { Card } from '../../../../core/components/controls/card/card';
import { TitleContainer } from '../../../../core/components/controls/title-container/title-container';

export const StrategiesSection: React.FC = () => {
  return (
    <section className='my-12'>
      <TitleContainer
        title='Die Strategien'
        content=' Erfolgreiche Anleger verfolgen mit ihren Aktien eine klare Anlagestrategie. Wir zeigen Dir, wie Du deren bewährte
        Kennzahlensysteme für Dein eigenes Stock Picking nutzen kannst.'
      />

      <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4'>
        {/** Card DIV */}
        <Card>
          <h3 className='mb-4 text-lg font-bold text-primary'>Dividenden-Strategie</h3>
          <p>
            Die Dividenden-Strategie ist eine langfristig orientierte Strategie zum Stock Picking von Unternehmen, die sich durch
            herausragende Dividenden-Kennzahlen auszeichnen. Sie ist angelehnt an das Buch &quot;Cool bleiben und Dividenden kassieren: Mit
            Aktien raus aus der Nullzins-Falle&quot; von Christian W. Röhl.
          </p>
        </Card>
        {/** Card HGI */}
        <Card>
          <h3 className='mb-4 text-lg font-bold text-primary'>High-Growth-Investing</h3>
          <p>
            Die High-Growth-Investing-Strategie ist eine Anlagestrategie, bei der Anleger in Unternehmen investieren, die ein hohes
            Wachstumspotenzial haben. Diese Unternehmen zeichnen sich durch ein starkes Umsatz- und Gewinnwachstum aus und haben oft
            innovative Produkte oder Dienstleistungen
          </p>
        </Card>
        {/** Card LEV */}
        <Card>
          <h3 className='mb-4 text-lg font-bold text-primary sm:text-xl'>Levermann-Strategie</h3>
          <p>
            Die Levermann-Strategie wird von der ehemaligen Fondsmanagerin Susan Levermann in ihrem Bestseller &quot;Der entspannte Weg zum
            Reichtum&quot; beschrieben. Die Anlagestrategie bewertet eine Aktie anhand von 13 Kriterien, die in der Vergangenheit zu
            überdurchschnittlicher Performance geführt haben.
          </p>
        </Card>
      </div>
    </section>
  );
};
