import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SnackbarState = {
  open: boolean;
  message: string;
  /** Der Schweretyp der Benachrichtigung */
  severety: 'success' | 'info' | 'warning' | 'error';
};

const initialSnackbarState: SnackbarState = {
  open: false,
  message: '',
  severety: 'success',
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialSnackbarState,
  reducers: {
    showSnackbar: (state: SnackbarState, action: PayloadAction<SnackbarState>) => {
      state.open = true;
      state.message = action.payload.message;
      state.severety = action.payload.severety;
    },
    hideSnackbar: (state: SnackbarState) => {
      state.open = false;
      state.message = '';
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
