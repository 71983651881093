import classNames from 'classnames';
import React, { useState } from 'react';
import { ActiveTabContext } from '../context/active-tab.context';

interface TabContextProps {
  value: any;
  children: React.ReactNode;
  className?: string;
}

export const TabContext: React.FC<TabContextProps> = (props: TabContextProps) => {
  const [activeTab, setActiveTab] = useState(props.value);

  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      <div className={classNames(props.className)}>{props.children}</div>
    </ActiveTabContext.Provider>
  );
};
