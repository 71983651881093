import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import React from 'react';
import { Link } from 'react-router-dom';

export const EmailNotice: React.FC = () => {
  return (
    <div className='border rounded-md mx-auto my-auto text-center bg-gradient-to-r from-slate-900 to-slate-700 text-white p-4 max-w-2xl'>
      <EmailOutlinedIcon fontSize='large' />
      <h2>Verify your Email</h2>
      <div className='space-y-8 mb-5'>
        <p>You will need to verify your email to complete registration</p>
        <p>We have sent you a verification email. If you haven&apos;t received it, please check that you wrote the right email address.</p>
      </div>

      <Link
        to='#'
        onClick={() => (window.location.href = `mailto:${process.env.REACT_APP_CONTACT_EMAIL}?subject=TradeSyze Contact`)}
        className='text-white border rounded-md p-1'
      >
        Contact support
      </Link>
    </div>
  );
};
