import React, { useEffect, useState } from 'react';
import { TotalScore } from '../../../../core/components/controls/total-score/total-score';
import { AnalysisDto } from '../../models/analyse.dto';
import { calculateTotalPoints } from '../../utils/analysis.utils';

interface AnalyseScoreProps {
  analysisModel?: AnalysisDto[];
  /** Multiplicator for total points */
  multiplicator?: number;
}

export const AnalyseScore: React.FC<AnalyseScoreProps> = (props) => {
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [reachedPoints, setReachedPoints] = useState<number>(0);

  useEffect(() => {
    if (props.analysisModel) {
      const totalPoints = props.multiplicator ? props.analysisModel.length * props.multiplicator : props.analysisModel.length;
      setTotalPoints(totalPoints);

      /** Calculate total points */
      setReachedPoints(calculateTotalPoints(props.analysisModel));
    }
  }, [props.analysisModel]);

  return <TotalScore reachedPoints={reachedPoints} totalPoints={totalPoints} />;
};
