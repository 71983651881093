import { ArcElement, Chart, ChartData, ChartOptions, Tooltip, TooltipItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useRef, useState } from 'react';
import { ColorScheme } from '../../../../core/models/enums/colorscheme.enum';

interface CountryChartProps {
  chartData: string[];
}

Chart.register(ArcElement, Tooltip, ChartDataLabels);

const initialData: ChartData<'pie', number[], unknown> = {
  labels: ['N/A'],
  datasets: [
    {
      data: [100],
      backgroundColor: Object.values(ColorScheme),
      borderWidth: 1,
    },
  ],
};

const calculateSplitData = (array: string[]): number[] => {
  const counts: Record<string, number> = {};

  array.forEach((element) => {
    counts[element] = (counts[element] || 0) + 1;
  });

  return Object.values(counts).map((count) => Math.round((count / array.length) * 100 * 100) / 100);
};

const getUniqueLabels = (array: string[]): string[] => [...new Set(array)];

const getInitialChartOptions = (): ChartOptions<'pie'> => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
      formatter: (_value: number, ctx: any) => {
        const label = ctx.chart.data.labels ? (ctx.chart.data.labels[ctx.dataIndex] as string) : '';
        return label.includes('N/A')
          ? label
          : label
              .split(' ')
              .map((word) => word[0])
              .join('');
      },
      font: { weight: 'bold' },
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem: TooltipItem<'pie'>) => `${tooltipItem.label}: ${tooltipItem.raw}%`,
      },
    },
  },
});

export const DiversificationChart: React.FC<CountryChartProps> = ({ chartData }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart<'pie'> | null>(null);
  const [chartDataState, setChartDataState] = useState<ChartData<'pie', number[], unknown>>(initialData);

  useEffect(() => {
    if (chartRef.current) {
      chartInstanceRef.current?.destroy();

      const ctx = chartRef.current.getContext('2d');
      if (ctx && chartDataState) {
        chartInstanceRef.current = new Chart<'pie'>(ctx, {
          type: 'pie',
          data: chartDataState,
          options: getInitialChartOptions(),
        });
      }
    }
  }, [chartDataState]);

  useEffect(() => {
    if (chartData.length > 0) {
      setChartDataState({
        labels: getUniqueLabels(chartData),
        datasets: [
          {
            data: calculateSplitData(chartData),
            backgroundColor: Object.values(ColorScheme),
          },
        ],
      });
    }
  }, [chartData]);

  return <canvas ref={chartRef} width='800' height='300'></canvas>;
};
