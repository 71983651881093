import classNames from 'classnames';
import React from 'react';
import { ReactComponent as ArrowDownSvg } from '../../../../../assets/svg/arrowdown.svg';
import { ReactComponent as ArrowUpSvg } from '../../../../../assets/svg/arrowup.svg';
import { getCurrencySymbol } from '../../../utils/currency.util';
import './growth-label.scss';

interface GrowthLabelProps {
  /** Display value */
  value: number;
  /** Symbol after value */
  symbol?: string;
  /** Use +- */
  useCalcSymbols?: boolean;
}

export const GrowthLabel: React.FC<GrowthLabelProps> = (props: GrowthLabelProps) => {
  return (
    <span className={classNames('price-difference', props.value >= 0 ? 'positiv' : 'negativ')}>
      {props.useCalcSymbols ? (
        <>
          {props.value > 0 ? '+' : <></>}
          {props.value < 0 ? '-' : <></>}
        </>
      ) : (
        <>
          {props.value > 0 ? <ArrowUpSvg /> : <></>}
          {props.value < 0 ? <ArrowDownSvg /> : <></>}
        </>
      )}

      {props.value >= 0 ? props.value.toFixed(2) : Math.abs(props.value).toFixed(2)}
      {props.symbol ? getCurrencySymbol(props.symbol) : ''}
    </span>
  );
};
