import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../core/components/controls/button/button';
import { ResetPasswordDto } from '../../../../core/models/dtos/reset-password.dto';
import { resetPassword } from '../../../../core/services/user.service';
import { showSnackbar, SnackbarState } from '../../../../redux-store/slices/snackbar.slice';

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>('');

  /** Handle reset user password */
  const onClickResetPassword = async (): Promise<void> => {
    const resetPasswordDto: ResetPasswordDto = {
      email: username,
    };

    const response = await resetPassword(resetPasswordDto);

    if (response) {
      dispatch(
        showSnackbar({
          message: 'Ihr Passwort wurde zurückgesetzt.',
          severety: 'success',
        } as SnackbarState)
      );

      /** Clear input field */
      setUsername('');
    }
  };

  return (
    <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full'>
        <h1 className='text-center mb-4 font-extrabold leading-none tracking-tight text-2xl  md:text-3xl xl:text-4xl'>
          Passwort <span className='text-secondary'>vergessen?</span>
        </h1>

        <p className='text-center'>
          Bitte gib Deine E-Mail Adresse ein, mit der Du Dich registriert hast. Wir werden Dir eine E-Mail senden mit einem neuen Passworts.
        </p>
      </div>

      {/* Email Input */}
      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        <div className='space-y-6'>
          <div>
            <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
              E-Mail
            </label>

            {/* E-Mail Input */}
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                value={username}
                placeholder='E-Mail'
                required
                className='block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>

          <Button type='submit' onClick={() => onClickResetPassword()}>
            Abschicken
          </Button>
        </div>
      </div>
    </div>
  );
};
