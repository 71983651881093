import { TopScorerTypes } from '../models/enums/topscorer.enum';

export const getTotalScore = (type: TopScorerTypes): number => {
  switch (type) {
    case TopScorerTypes.DIV:
      return 15;
    case TopScorerTypes.HGI:
      return 18;
    case TopScorerTypes.LEV:
      return 13;
  }
};
