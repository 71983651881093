import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../../core/components/controls/card/card';

interface IndiceWidgetProps {
  url: string;
  symbol: string;
  name: string;
  count: number;
}

export const IndiceWidget: React.FC<IndiceWidgetProps> = (props: IndiceWidgetProps) => {
  return (
    <Link to={props.url}>
      <Card hoverEffect={true}>
        <div className='indice-widget flex flex-col items-center gap-2'>
          <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>{props.symbol}</h3>
          <span>{props.count}</span>
          <p className='mt-2 sm:block text-center'>{props.name}</p>
        </div>
      </Card>
    </Link>
  );
};
