import classNames from 'classnames';
import React from 'react';
import './button.scss';

interface ButtonProps {
  /** Define button type */
  type?: 'submit' | 'reset' | 'button';
  /** OnClick event */
  onClick?: () => void;
  /** Additional classnames */
  className?: string;
  /** React child node */
  children?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <button type={props.type} className={classNames('custom-btn', props.className)} onClick={props.onClick} disabled={props.disabled}>
      <span className='btn-icon'>{props.icon}</span>
      <span className={classNames('btn-children', props.icon ? 'hide-children' : '')}>{props.children}</span>
    </button>
  );
};
