import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { IndiceTopScorer } from './components/indice-topscorer/indice-topscorer';
import { IndiceModule } from './indice.module';
import { WorldIndices } from './world-indices/world-indices';

export const IndiceRoutes: React.FC = () => (
  <IndiceModule>
    <Routes>
      <Route path=':symbol' element={<IndiceTopScorer />} />
      <Route path='world' element={<WorldIndices />} />
    </Routes>
  </IndiceModule>
);
