import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';
import { Tab, TabContext, TabPanel, Tabs } from '../../core/components/controls/tabs';
import { StockDto } from '../../core/models/dtos/stock.dto';
import { ClientRoutes } from '../../core/models/enums/client-routes.enum';
import { TopScorerTypes } from '../../core/models/enums/topscorer.enum';
import { loadStock } from '../../core/services/stock.service';
import { setStockDto } from '../../redux-store/slices/stock.slice';
import { DeepDive } from './components/deep-dive/deep-dive';
import { DividendAnalyse } from './components/dividend-analyse/dividend-analyse';
import { HighGrowInvestingAnalyse } from './components/high-grow-investing-analyse/high-grow-investing-analyse';
import { LevermannAnalysis } from './components/levermann-analysis/levermann-analysis';
import { StockChart } from './components/stock-chart/stock-chart';
import { StockHeader } from './components/stock-header/stock-header';
import { StockNews } from './components/stock-news/stock-news';
import { StockOverview } from './components/stock-overview/stock-overview';

export const StockModule: React.FC = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);

  const dispatch = useDispatch();

  const [tabValue, setTabValue] = React.useState(TopScorerTypes.DIV);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      if (symbol) {
        const stockDto: StockDto = await loadStock(symbol);
        dispatch(setStockDto(stockDto));
      }
    };

    /** Set tab from url query */
    const tabQuery = urlQuery.get('tab');
    if (tabQuery) {
      setTabValue(tabQuery as TopScorerTypes);
    }

    loadData();
  }, [symbol]);

  /** Handle change tab view */
  const changeTabView = (newValue: TopScorerTypes): void => {
    setTabValue(newValue);
    navigate(
      {
        pathname: `${ClientRoutes.STOCK}/${symbol}`,
        search: `?${createSearchParams({ tab: newValue })}`,
      },
      {
        replace: true,
      }
    );
  };

  return (
    <>
      <StockHeader />

      <SinglePageContainer className={'mb-5 px-4'}>
        <div className='flex flex-wrap md:flex-nowrap gap-4'>
          <div className='w-full md:w-2/3'>
            <StockChart symbol={symbol} />

            {/* Analysis */}
            <section className='my-4'>
              <TabContext value={tabValue}>
                <Tabs onChange={changeTabView}>
                  <Tab label='Dividenden Analyse' value={TopScorerTypes.DIV} />
                  <Tab label='High-Growth-Investing' value={TopScorerTypes.HGI} />
                  <Tab label='Levermann' value={TopScorerTypes.LEV} />
                </Tabs>

                <TabPanel value={TopScorerTypes.DIV}>
                  <DividendAnalyse />
                </TabPanel>
                <TabPanel value={TopScorerTypes.HGI}>
                  <HighGrowInvestingAnalyse />
                </TabPanel>
                <TabPanel value={TopScorerTypes.LEV}>
                  <LevermannAnalysis />
                </TabPanel>
              </TabContext>
            </section>

            <StockNews />
          </div>

          <div className='w-full md:w-1/3'>
            <div className=''>
              <StockOverview />
            </div>
            <div className='mt-4'>
              <DeepDive />
            </div>
          </div>
        </div>
      </SinglePageContainer>
    </>
  );
};
