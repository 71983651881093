import { Chart, ChartData, ChartDataset } from 'chart.js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DividendModel } from '../../../../core/models/dividend.model';
import { ColorScheme } from '../../../../core/models/enums/colorscheme.enum';
import { loadPortfolioDividends } from '../../../../core/services/portfolio.service';

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const initialData: ChartData<'bar', number[], string> = {
  labels,
  datasets: [],
};

export const DividendChart: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  const [dividends, setDividends] = useState<DividendModel[]>();
  const [chartData, setChartData] = useState<ChartData<'bar', number[], unknown>>(initialData);

  /** Load dividends from API */
  useEffect(() => {
    const loadDividends = async (): Promise<void> => {
      if (!dividends) {
        const dividendsData = await loadPortfolioDividends();
        setDividends(dividendsData);
      }
    };

    loadDividends();
  }, []);

  /** Convert DividendModel to ChartJS dataset */
  useEffect(() => {
    const updateChartData = { ...chartData };

    const colorScheme = Object.values(ColorScheme);

    if (dividends) {
      updateChartData.datasets = dividends.map((dividend, index) => {
        return {
          label: dividend.symbol,
          data: getChartData(dividend),
          backgroundColor: colorScheme[index],
        } as ChartDataset<'bar', number[]>;
      });
    }

    setChartData(updateChartData);
  }, [dividends]);

  /** Chart.js lifecycle */
  useEffect(() => {
    if (chartRef.current) {
      /** Destroy current chart to rerender */
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const myChartRef = chartRef.current.getContext('2d');
      if (myChartRef && chartData) {
        chartInstanceRef.current = new Chart<'bar'>(myChartRef, {
          type: 'bar',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                mode: 'x',
                filter: (tooltipItem: any) => Number(tooltipItem.dataset.data[tooltipItem.dataIndex]) > 0,
                callbacks: {
                  footer: function (items: any) {
                    const sum = items.reduce((a: any, b: any) => a + b.raw, 0).toFixed(2);
                    return `Gesamt: ${sum}`;
                  },
                },
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                  callback: (value) => {
                    return value;
                  },
                },
              },
            },
          },
        });
      }
    }
  }, [chartData]);

  const getChartData = (dividend: DividendModel): (number | null)[] => {
    const dividendData: (number | null)[] = [];

    for (let i = 1; i <= 12; i++) {
      // Convert number to month format like: 7 => 07
      const formattedNumber = i.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

      const foundAmount = dividend.dividends.find((x) => moment.unix(x.date).format('MM') === `${formattedNumber}`);

      if (foundAmount) {
        dividendData.push(foundAmount.amount);
      } else {
        dividendData.push(null);
      }
    }

    return dividendData;
  };

  return (
    <section className='bg-white rounded-lg border border-gray-100'>
      <canvas ref={chartRef} width='800' height='300'></canvas>
    </section>
  );
};
