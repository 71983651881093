import React from 'react';
import { Outlet } from 'react-router-dom';
import './app.scss';
import { NotificationSnackbar } from './core/components/controls/notification-snackbar/notification-snackbar';
import { AppFooter } from './core/components/layout/footer/footer';
import { Navigation } from './core/components/layout/navigation/navigation';

const App: React.FC = () => {
  return (
    <>
      <Navigation />
      {/* <NavigationMat /> */}
      <main className='outlet-fitting'>
        <NotificationSnackbar />
        <Outlet />
      </main>
      <AppFooter />
    </>
  );
};

export default App;
