import { configureStore } from '@reduxjs/toolkit';
import portfolioSlice from './slices/portfolio.slice';
import snackbarSlice from './slices/snackbar.slice';
import stockSlice from './slices/stock.slice';
import userSlice from './slices/user.slice';

const store = configureStore({
  reducer: {
    user: userSlice,
    portfolio: portfolioSlice,
    stock: stockSlice,
    snackbar: snackbarSlice,
  },
});

export default store;

export type ReduxStore = ReturnType<typeof store.getState>;
