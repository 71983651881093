import React, { useEffect, useState } from 'react';
import { Badge } from '../badge/badge';

interface TotalScoreProps {
  /** Erreichte Punktzahl */
  reachedPoints: number;
  /** Maximale punktzahl die zu erreichen ist */
  totalPoints: number;
}

export const TotalScore: React.FC<TotalScoreProps> = (props: TotalScoreProps) => {
  const [chipColor, setChipColor] = useState<'default' | 'success' | 'warning' | 'error'>('default');
  const [scoreLabel, setScoreLabel] = useState<string>('');

  /** Calculate and select chip color */
  useEffect(() => {
    const splittedPoints = props.totalPoints / 3;

    if (props.reachedPoints <= splittedPoints) {
      setChipColor('error');
    } else if (props.reachedPoints >= props.totalPoints - splittedPoints) {
      setChipColor('success');
    } else if (props.reachedPoints >= props.totalPoints - splittedPoints * 2) {
      setChipColor('warning');
    } else {
      setChipColor('default');
    }

    if (isNaN(props.reachedPoints)) setScoreLabel('N/A');
    else setScoreLabel(`${props.reachedPoints}/${props.totalPoints}`);
  }, [props.reachedPoints]);

  return <Badge label={scoreLabel} color={chipColor} />;
};
