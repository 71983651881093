import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StockDto } from '../../../../core/models/dtos/stock.dto';
import { YahooQuoteSummaryModel } from '../../../../core/models/interfaces/yahoo/yahoo-quote-summary.model';
import { loadQuoteSummary } from '../../../../core/services/yahoo.service';
import { separateDigits } from '../../../../core/utils/format-number.utils';
import { ReduxStore } from '../../../../redux-store/store';

export const StockOverview: React.FC = () => {
  const { symbol } = useParams();

  const [quoteSummary, setQuoteSummary] = useState<YahooQuoteSummaryModel>();
  const stockDto = useSelector<ReduxStore, StockDto | undefined>((state) => state.stock.stockDto);

  /** Load quote asset profile of quote from symbol */
  useEffect(() => {
    const loadQuoteAssetProfile = async (): Promise<void> => {
      if (symbol) {
        const quoteSummary = await loadQuoteSummary(symbol, 'assetProfile,calendarevents');
        setQuoteSummary(quoteSummary);
      }
    };

    loadQuoteAssetProfile();
  }, [symbol]);

  return (
    <section>
      <div className='bg-white shadow-lg rounded-lg border border-gray-200'>
        <header className='px-5 border-b border-gray-100 flex items-center'>
          <h2 className='font-semibold text-gray-800'>Überblick</h2>
        </header>
        <div className='grid xs:grid-cols-1 md:grid-cols-2 rounded-lg p-5'>
          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Symbol</span>
            <span>{symbol}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Börse</span>
            <span>{stockDto?.exchangeName}</span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Industrie</span>
            <span>{stockDto?.stockCompanyOverview?.industry}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Sektor</span>
            <span>{stockDto?.stockCompanyOverview?.sector}</span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Mitarbeiter</span>
            <span>{separateDigits(stockDto?.stockCompanyOverview?.fullTimeEmployees || 0)}</span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Land</span>
            <span>{stockDto?.stockCompanyOverview?.country}</span>
          </div>

          <div className='flex flex-col'>
            {quoteSummary?.calendarEvents?.dividendDate.fmt ? (
              <>
                <span className='text-xs font-semibold text-gray-400'>Dividende</span>
                <span>{moment(quoteSummary?.calendarEvents.dividendDate?.fmt).format('DD.MM.YYYY')}</span>
              </>
            ) : (
              <>
                <span className='text-xs font-semibold text-gray-400'>Ex-Dividende</span>
                <span>
                  {quoteSummary?.calendarEvents?.exDividendDate?.fmt ? (
                    moment(quoteSummary?.calendarEvents.exDividendDate?.fmt).format('DD.MM.YYYY')
                  ) : (
                    <>-</>
                  )}
                </span>
              </>
            )}
          </div>

          <div className='flex flex-col'>
            <span className='text-xs font-semibold text-gray-400'>Webseite</span>
            <span>
              <a href={`https://${stockDto?.stockCompanyOverview?.website}`} target='_blank' rel='noreferrer'>
                {stockDto?.stockCompanyOverview?.website}
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
