import React from 'react';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';

interface IndiceModuleProps {
  children: React.ReactNode;
}

export const IndiceModule: React.FC<IndiceModuleProps> = (props: IndiceModuleProps) => {
  return <SinglePageContainer>{props.children}</SinglePageContainer>;
};
