import React from 'react';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';

export const PrivacyPolicyModule: React.FC = () => {
  return (
    <SinglePageContainer>
      <h1>1) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h1>
      <p>
        <b>1.1</b>Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse. Im Folgenden informieren wir Sie über
        den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit
        denen Sie persönlich identifiziert werden können.
      </p>
      <p>
        <b>1.2</b>Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Bogdan
        Schreiber, St-Amand-Straße 88a, 56626 Andernach, Deutschland, Tel.: 0176/62929358, E-Mail: info@bssoftware.de. Der für die
        Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige natürliche oder juristische Person, die allein oder gemeinsam
        mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
      </p>
      <p>
        <b>1.3</b>Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogener Daten und anderer
        vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung. Sie können eine
        verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile erkennen.
      </p>
      <h1>2) Datenerfassung beim Besuch unserer Website</h1>
      <p>
        Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen
        übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere
        Website aufrufen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:
      </p>
      <ul>
        <li>Unsere besuchte Website</li>
        <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
        <li>Menge der gesendeten Daten in Byte</li>
        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
      </ul>
      <p>
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der
        Stabilität und Funktionalität unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir
        behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige
        Nutzung hinweisen.
      </p>
      <h1>3) Cookies</h1>
      <p>
        Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir Cookies,
        also kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Teilweise werden diese Cookies nach Schließen des Browsers
        automatisch wieder gelöscht (sog. „Session-Cookies“), teilweise verbleiben diese Cookies länger auf Ihrem Endgerät und ermöglichen
        das Speichern von Seiteneinstellungen (sog. „persistente Cookies“). Im letzteren Fall können Sie die Speicherdauer der Übersicht zu
        den Cookie-Einstellungen Ihres Webbrowsers entnehmen.
        <br />
        Sofern durch einzelne von uns eingesetzte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gemäß
        Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchführung des Vertrages, gemäß Art. 6 Abs. 1 lit. a DSGVO im Falle einer erteilten
        Einwilligung oder gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestmöglichen Funktionalität
        der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
        <br />
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme
        entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen können.
        <br />
        Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalität unserer Website eingeschränkt sein kann.
      </p>
      <h1>4) Kontaktaufnahme</h1>
      <p>
        Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden – ausschließlich zum Zweck der Bearbeitung und
        Beantwortung Ihres Anliegens und nur im dafür erforderlichen Umfang – personenbezogene Daten verarbeitet. Rechtsgrundlage für die
        Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO.
        Zielt Ihre Kontaktierung auf einen Vertrag ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
        Ihre Daten werden gelöscht, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist
        und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen
      </p>
      <h1>5) Datenverarbeitung bei Eröffnung eines Kundenkontos</h1>
      <p>
        Gemäß Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten im jeweils erforderlichen Umfang weiterhin erhoben und verarbeitet,
        wenn Sie uns diese bei der Eröffnung eines Kundenkontos mitteilen. Welche Daten für die Kontoeröffnung erforderlich sind, entnehmen
        Sie der Eingabemaske des entsprechenden Formulars auf unserer Website. Eine Löschung Ihres Kundenkontos ist jederzeit möglich und
        kann durch eine Nachricht an die o.g. Adresse des Verantwortlichen erfolgen. Nach Löschung Ihres Kundenkontos werden Ihre Daten
        gelöscht, sofern alle darüber geschlossenen Verträge vollständig abgewickelt sind, keine gesetzlichen Aufbewahrungsfristen
        entgegenstehen und unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
      </p>
      <h1>6) Datenverarbeitung zur Bestellabwicklung</h1>
      <p>
        <b>6.1</b>Soweit für die Vertragsabwicklung zu Liefer- und Zahlungszwecken erforderlich, werden die von uns erhobenen
        personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. b DSGVO an das beauftragte Transportunternehmen und das beauftragte Kreditinstitut
        weitergegeben.
      </p>
      <p>
        Sofern wir Ihnen auf Grundlage eines entsprechenden Vertrages Aktualisierungen für Waren mit digitalen Elementen oder für digitale
        Produkte schulden, verarbeiten wir die von Ihnen bei der Bestellung übermittelten Kontaktdaten (Name, Anschrift, Mailadresse), um
        Sie im Rahmen unserer gesetzlichen Informationspflichten gemäß Art. 6 Abs. 1 lit. c DSGVO auf geeignetem Kommunikationsweg (etwa
        postalisch oder per Mail) über anstehende Aktualisierungen im gesetzlich vorgesehenen Zeitraum persönlich zu informieren. Ihre
        Kontaktdaten werden hierbei streng zweckgebunden für Mitteilungen über von uns geschuldete Aktualisierungen verwendet und zu diesem
        Zweck durch uns nur insoweit verarbeitet, wie dies für die jeweilige Information erforderlich ist.
      </p>
      <p>
        Zur Abwicklung Ihrer Bestellung arbeiten wir ferner mit dem / den nachstehenden Dienstleister(n) zusammen, die uns ganz oder
        teilweise bei der Durchführung geschlossener Verträge unterstützen. An diese Dienstleister werden nach Maßgabe der folgenden
        Informationen gewisse personenbezogene Daten übermittelt.
      </p>
      <p>
        <b>6.2</b>Verwendung von Paymentdienstleistern (Zahlungsdiensten)
      </p>
      <p>
        - Paypal
        <br />
        Bei Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal oder – falls angeboten - `&quot;`Kauf auf Rechnung`&quot;`
        oder „Ratenzahlung“ via PayPal geben wir Ihre Zahlungsdaten im Rahmen der Zahlungsabwicklung an die PayPal (Europe) S.a.r.l. et Cie,
        S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (nachfolgend `&quot;`PayPal`&quot;`), weiter. Die Weitergabe erfolgt gemäß Art. 6
        Abs. 1 lit. b DSGVO und nur insoweit, als dies für die Zahlungsabwicklung erforderlich ist.
        <br />
        PayPal behält sich für die Zahlungsmethoden Kreditkarte via PayPal, Lastschrift via PayPal oder – falls angeboten - `&quot;`Kauf auf
        Rechnung`&quot;` oder „Ratenzahlung“ via PayPal die Durchführung einer Bonitätsauskunft vor. Hierfür werden Ihre Zahlungsdaten
        gegebenenfalls gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis des berechtigten Interesses von PayPal an der Feststellung Ihrer
        Zahlungsfähigkeit an Auskunfteien weitergegeben. Das Ergebnis der Bonitätsprüfung in Bezug auf die statistische
        Zahlungsausfallwahrscheinlichkeit verwendet PayPal zum Zweck der Entscheidung über die Bereitstellung der jeweiligen
        Zahlungsmethode. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit Score-Werte in das Ergebnis
        der Bonitätsauskunft einfließen, haben sie ihre Grundlage in einem wissenschaftlich anerkannten mathematisch-statistischen
        Verfahren. In die Berechnung der Score-Werte fließen unter anderem, aber nicht ausschließlich, Anschriftendaten ein. Weitere
        datenschutzrechtliche Informationen, unter anderem zu den verwendeten Auskunfteien, entnehmen Sie bitte der Datenschutzerklärung von
        PayPal:{' '}
        <a href='https://www.paypal.com/de/webapps/mpp/ua/privacy-full' target='_blank' rel='noopener noreferrer'>
          https://www.paypal.com
          <wbr />
          /de
          <wbr />
          /webapps
          <wbr />
          /mpp
          <wbr />
          /ua
          <wbr />
          /privacy-full
        </a>
        <br />
        Sie können dieser Verarbeitung Ihrer Daten jederzeit durch eine Nachricht an PayPal widersprechen. Jedoch bleibt PayPal ggf.
        weiterhin berechtigt, Ihre personenbezogenen Daten zu verarbeiten, sofern dies zur vertragsgemäßen Zahlungsabwicklung erforderlich
        ist.
      </p>
      <h1>7) Webanalysedienste</h1>
      <p>
        - Jetpack
        <br />
        Dieses Angebot nutzt den Webanalysedienst Jetpack (vormals WordPress.com-Stats), der von Automattic Inc., 60 29th Street #343, San
        Francisco, CA 94110-4929, USA, unter Einsatz der Trackingtechnologie von Quantcast Inc., 201 3rd St, Floor 2, San Francisco, CA
        94103-3153, USA betrieben wird. Mithilfe von Jetpack werden pseudonymisierte Besucherdaten gesammelt, ausgewertet und gespeichert.
        Aus diesen Daten können zum selben Zweck pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden. Jetpack verwendet sog.
        Cookies, also kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden. Diese
        Cookies dienen unter anderem dazu, den Browser wiederzuerkennen, und ermöglichen so eine genauere Ermittlung der Statistikdaten. Die
        Daten der IP-Adresse des Nutzers werden ebenfalls erhoben, sie werden aber sofort nach der Erhebung und vor deren Speicherung
        pseudonymisiert, um eine Personenbeziehbarkeit auszuschließen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung
        dieser Website (einschließlich der pseudonymisierten IP-Adresse) werden zur Wahrung der oben genannten Interessen auf einen Server
        in den USA übertragen und dort gespeichert.
        <br />
        Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von Cookies für das Auslesen von Informationen auf dem verwendeten
        Endgerät, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt
        haben. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie diesen Dienst in dem auf
        der Webseite bereitgestellten „Cookie-Consent-Tool“ deaktivieren.
        <br />
        <br />- WP Statistics
        <br />
        Unsere Webseite verwendet das Plugin `&quot;`WP Statistics`&quot;` von <a href='https://veronalabs.com/'>VeronaLabs</a>, um
        detaillierte Analysen über die Besucher unserer Webseite zu erstellen. WP Statistics sammelt und speichert Daten wie IP-Adresse,
        besuchte Seiten, Verweildauer und Herkunft des Besuchers. Diese Daten dienen ausschließlich der Optimierung unserer Webseite und
        werden nicht zur Identifizierung einzelner Personen verwendet. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO, da wir ein berechtigtes Interesse an der Analyse des Nutzerverhaltens haben, um unser Webangebot zu verbessern. Alle
        erfassten Daten werden anonymisiert und verbleiben auf unserem Server, eine Weitergabe an Dritte findet nicht statt. Sollten Sie
        Fragen zur Datenverarbeitung haben, können Sie uns jederzeit unter den in der Datenschutzerklärung angegebenen Kontaktinformationen
        erreichen.
      </p>
      <h1>8) Seitenfunktionalitäten</h1>
      <p>
        <b>8.1</b>- FontAwesome
        <br />
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts von „FontAwesome“, einem Dienst der Fonticons,
        Inc., 710 Blackhorn Dr, Carl Junction, 64834, MO, USA („FontAwesome“). Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web
        Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen.
        <br />
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von FontAwesome aufnehmen. Hierbei kann es auch zu
        einer Übermittlung von personenbezogenen Daten an die Server von FontAwesome in den USA kommen. Auf diese Weise erlangt FontAwesome
        Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Verarbeitung von personenbezogenen Daten im Zuge
        der Verbindungsaufnahme mit dem Anbieter der Schriftarten wird nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO
        dazu Ihre ausdrückliche Einwilligung erteilt haben. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft
        widerrufen, indem Sie diesen Dienst in dem auf der Webseite bereitgestellten „Cookie-Consent-Tool“ deaktivieren.Wenn Ihr Browser Web
        Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
        <br />
        Weitere Informationen zu FontAwesome finden Sie unter:{' '}
        <a href='https://fontawesome.com/privacy' target='_blank' rel='noopener noreferrer'>
          https://fontawesome.com
          <wbr />
          /privacy
        </a>
        <br />- Google Web Fonts
        <br />
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts die von der Google Ireland Limited, Gordon
        House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“) bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die
        benötigten Web Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen.
        <br />
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierbei kann es auch zu einer
        Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen. Auf diese Weise erlangt Google Kenntnis
        darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Verarbeitung von personenbezogenen Daten im Zuge der
        Verbindungsaufnahme mit dem Anbieter der Schriftarten wird nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu
        Ihre ausdrückliche Einwilligung erteilt haben. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft
        widerrufen, indem Sie diesen Dienst in dem auf der Webseite bereitgestellten „Cookie-Consent-Tool“ deaktivieren. Wenn Ihr Browser
        Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
        <br />
        Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
        <a href='https://developers.google.com/fonts/faq' target='_blank' rel='noopener noreferrer'>
          https://developers.google.com
          <wbr />
          /fonts
          <wbr />
          /faq
        </a>{' '}
        und in der Datenschutzerklärung von Google:{' '}
        <a href='https://www.google.com/policies/privacy/' target='_blank' rel='noopener noreferrer'>
          https://www.google.com
          <wbr />
          /policies
          <wbr />
          /privacy
          <wbr />/
        </a>
      </p>
      <p>
        <b>8.2</b>Google Kundenrezensionen (ehemals Google Zertifizierter-Händler-Programm)
      </p>
      <p>
        Wir arbeiten mit Google im Rahmen des Programms „Google Kundenrezensionen“ zusammen. Der Anbieter ist Google Ireland Limited, Gordon
        House, 4 Barrow St, Dublin, D04 E5W5, Irland (“Google”). Dieses Programm gibt uns die Möglichkeit, Kundenrezensionen von Nutzern
        unserer Website einzuholen. Hierbei werden Sie nach einem Einkauf auf unserer Website gefragt, ob Sie an einer E-Mail-Umfrage von
        Google teilnehmen möchten. Wenn Sie Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO erteilen, übermitteln wir Ihre E-Mail-Adresse
        an Google. Sie erhalten eine E-Mail von Google Kundenrezensionen, in der Sie gebeten werden, die Kauferfahrung auf unserer Website
        zu bewerten. Die von Ihnen abgegebene Bewertung wird anschließend mit unseren anderen Bewertungen zusammengefasst und in unserem
        Logo Google Kundenrezensionen sowie in unserem Merchant Center-Dashboard angezeigt. Außerdem wird Ihre Bewertung für Google
        Verkäuferbewertungen genutzt. Im Rahmen der Nutzung von Google Kundenrezensionen kann es auch zu einer Übermittlung von
        personenbezogenen Daten an die Server der Google LLC. in den USA kommen.
      </p>
      <p>
        Sie können Ihre Einwilligung jederzeit durch eine Nachricht an den für die Datenverarbeitung Verantwortlichen oder gegenüber Google
        widerrufen.
      </p>
      <p>
        Weitere Informationen zum Datenschutz von Google im Zusammenhang mit dem Programm Google Kundenrezensionen können Sie unter
        nachstehendem Link abrufen:{' '}
        <a href='https://support.google.com/merchants/answer/7188525?hl=de' target='_blank' rel='noopener noreferrer'>
          https://support.google.com
          <wbr />
          /merchants
          <wbr />
          /answer
          <wbr />
          /7188525
          <wbr />
          ?hl=de
        </a>
      </p>
      <p>
        Weitere Informationen zum Datenschutz von Google Verkäuferbewertungen können Sie unter diesem Link nachlesen:{' '}
        <a href='https://support.google.com/google-ads/answer/2375474' target='_blank' rel='noopener noreferrer'>
          https://support.google.com
          <wbr />
          /google-ads
          <wbr />
          /answer
          <wbr />
          /2375474
        </a>
      </p>
      <h1>9) Tools und Sonstiges</h1>
      <p>Cookie-Consent-Tool</p>
      <p>
        Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen für einwilligungspflichtige Cookies und cookie-basierte Anwendungen
        ein sog. „Cookie-Consent-Tool“. Das „Cookie-Consent-Tool“ wird Nutzern bei Seitenaufruf in Form einer interaktive Benutzeroberfläche
        angezeigt, auf welcher sich per Häkchensetzung Einwilligungen für bestimmte Cookies und/oder cookie-basierte Anwendungen erteilen
        lassen. Hierbei werden durch den Einsatz des Tools alle einwilligungspflichtigen Cookies/Dienste nur dann geladen, wenn der
        jeweilige Nutzer entsprechende Einwilligungen per Häkchensetzung erteilt. So wird sichergestellt, dass nur im Falle einer erteilten
        Einwilligung derartige Cookies auf dem jeweiligen Endgerät des Nutzers gesetzt werden.
        <br />
        Das Tool setzt technisch notwendige Cookies, um Ihre Cookie-Präferenzen zu speichern. Personenbezogene Nutzerdaten werden hierbei
        grundsätzlich nicht verarbeitet.
        <br />
        Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung oder Protokollierung von Cookie-Einstellungen doch zur Verarbeitung
        personenbezogener Daten (wie etwa der IP-Adresse), erfolgt diese gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten
        Interesses an einem rechtskonformen, nutzerspezifischen und nutzerfreundlichen Einwilligungsmanagement für Cookies und mithin an
        einer rechtskonformen Ausgestaltung unseres Internetauftritts.
        <br />
        Weitere Rechtsgrundlage für die Verarbeitung ist ferner Art. 6 Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der
        rechtlichen Verpflichtung, den Einsatz technisch nicht notwendiger Cookies von der jeweiligen Nutzereinwilligung abhängig zu machen.
        <br />
        Weitere Informationen zum Betreiber und den Einstellungsmöglichkeiten des Cookie-Consent-Tools finden Sie direkt in der
        entsprechenden Benutzeroberfläche auf unserer Website.
      </p>
      <h1>10) Rechte des Betroffenen</h1>
      <p>
        <b>10.1</b>Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer
        personenbezogenen Daten die nachstehenden Betroffenenrechte (Auskunfts- und Interventionsrechte), wobei für die jeweiligen
        Ausübungsvoraussetzungen auf die angeführte Rechtsgrundlage verwiesen wird:
      </p>
      <ul>
        <li>Auskunftsrecht gemäß Art. 15 DSGVO;</li>
        <li>Recht auf Berichtigung gemäß Art. 16 DSGVO;</li>
        <li>Recht auf Löschung gemäß Art. 17 DSGVO;</li>
        <li>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO;</li>
        <li>Recht auf Unterrichtung gemäß Art. 19 DSGVO;</li>
        <li>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO;</li>
        <li>Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO;</li>
        <li>Recht auf Beschwerde gemäß Art. 77 DSGVO.</li>
      </ul>
      <p>
        <b>10.2</b>WIDERSPRUCHSRECHT
      </p>
      <p>
        WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES
        VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE
        VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
        <br />
        MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
        ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE
        UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
        DIENT.
      </p>
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
        GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH
        WIE OBEN BESCHRIEBEN AUSÜBEN.
      </p>
      <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>
      <h1>11) Dauer der Speicherung personenbezogener Daten</h1>
      <p>
        Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und
        – sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche
        Aufbewahrungsfristen).
      </p>
      <p>
        Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO
        werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.
      </p>
      <p>
        Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher
        Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der
        Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind
        und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
      </p>
      <p>
        Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange
        gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es sei denn, wir können zwingende
        schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen,
        oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <p>
        Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
        diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt.
      </p>
      <p>
        Sofern sich aus den sonstigen Informationen dieser Erklärung über spezifische Verarbeitungssituationen nichts anderes ergibt, werden
        gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie für die Zwecke, für die sie erhoben oder auf sonstige Weise
        verarbeitet wurden, nicht mehr notwendig sind.
      </p>
    </SinglePageContainer>
  );
};
