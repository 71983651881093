import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type UserState = {
  isAuthorized: boolean;
};

const initialUserState: UserState = {
  isAuthorized: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setIsUserLoggedIn: (state: UserState, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
  },
});

export const { setIsUserLoggedIn } = userSlice.actions;

export default userSlice.reducer;
