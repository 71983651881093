import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { StockDto } from '../../../../core/models/dtos/stock.dto';
import { ReduxStore } from '../../../../redux-store/store';
import './deep-dive.scss';

export const DeepDive: React.FC = () => {
  const stock = useSelector<ReduxStore, StockDto | undefined>((state) => state.stock.stockDto);

  return (
    <div className={classNames('deepDiveContainer bg-white rounded-lg')}>
      <div className='deepDivListItem'>
        <img src={`${process.env.PUBLIC_URL}/assets/img/yahoofinance.png`} alt='yahoofinance-img' />
        <a href={`https://finance.yahoo.com/quote/${stock?.symbol}`} target='_blank' rel='noreferrer'>
          Profil auf Yahoo Finance &rarr;
        </a>
      </div>
    </div>
  );
};
