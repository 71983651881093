import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabContext, TabPanel, Tabs } from '../../../../core/components/controls/tabs';
import { ReduxStore } from '../../../../redux-store/store';
import { PortfolioDto } from '../../models/portfolio.dto';
import { DiversificationChart } from '../diversification-chart/diversification-chart';

export const DiversificationSection: React.FC = () => {
  const portfolioStocks = useSelector<ReduxStore, PortfolioDto[]>((state) => state.portfolio.portfolioDtos);
  const [countries, setCountries] = useState<string[]>([]);
  const [sectors, setSectors] = useState<string[]>([]);

  useEffect(() => {
    getCountries(portfolioStocks);
    getSectors(portfolioStocks);
  }, [portfolioStocks.length]);

  const [tabValue, setTabValue] = React.useState('1');

  const handleChange = (newValue: string): void => {
    setTabValue(newValue);
  };

  const getCountries = (stocks: PortfolioDto[]): void => {
    const countries = stocks.map((portfolio: PortfolioDto) => {
      if (portfolio?.stock.stockCompanyOverview?.country) return portfolio.stock.stockCompanyOverview.country;
      else return 'N/A';
    });

    setCountries(countries);
  };

  const getSectors = (stocks: PortfolioDto[]): void => {
    const sectors = stocks.map((portfolio: PortfolioDto) => {
      if (portfolio.stock.stockCompanyOverview?.sector) return portfolio.stock.stockCompanyOverview?.sector;
      else return 'N/A';
    });

    setSectors(sectors);
  };

  return (
    <section className='bg-white rounded-lg border border-gray-100'>
      <TabContext value={tabValue}>
        <Tabs onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Länder' value='1' />
          <Tab label='Sektoren' value='2' />
        </Tabs>

        <TabPanel value='1'>
          <DiversificationChart chartData={countries} />
        </TabPanel>
        <TabPanel value='2'>
          <DiversificationChart chartData={sectors} />
        </TabPanel>
      </TabContext>
    </section>
  );
};
