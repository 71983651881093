import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { ActiveTabContext } from '../../context/active-tab.context';
import './tabs.scss';

interface TabsProps {
  onChange?: (value: any) => void;
  children: React.ReactNode;
  className?: string;
}

export const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const { activeTab } = useContext(ActiveTabContext);

  /** Trigger on change event */
  useEffect(() => {
    if (props.onChange) props.onChange(activeTab);
  }, [activeTab]);

  return (
    <div className={classNames('tab-container', props.className)}>
      <div className='tabs'>{props.children}</div>
    </div>
  );
};
