import { jwtDecode } from 'jwt-decode';
import { LocalStorageTypes } from '../models/enums/local-storage.enum';
import { getItemLocalStorage, removeItemInLocalStorage } from '../services/local-storage.service';

/** Check token is valid and not expired */
export const isTokenValid = (): boolean => {
  const token = getItemLocalStorage(LocalStorageTypes.JWT_TOKEN);

  /** Return false, if no token available in local storage */
  if (!token) return false;

  const jwtPayload: TokenPayload = jwtDecode(token);

  const MS_MULTIPLIER = 1000;
  if (new Date(jwtPayload.exp * MS_MULTIPLIER) < new Date()) {
    /** Remove invalid token from local storage */
    removeItemInLocalStorage(LocalStorageTypes.JWT_TOKEN);
    return false;
  }

  return true;
};

/** Sign out current logged in user */
export const logOut = (): void => {
  removeItemInLocalStorage(LocalStorageTypes.JWT_TOKEN);
  window.location.reload();
};
