export enum ColorScheme {
  COLOR1 = 'rgba(143, 204, 240, 0.5)',
  COLOR2 = 'rgb(98, 184, 234, 0.5)',
  COLOR3 = 'rgb(53, 164, 227, 0.5)',
  COLOR4 = 'rgb(28, 138, 202, 0.5)',
  COLOR5 = 'rgb(21, 107, 157, 0.5)',
  COLOR6 = 'rgb(15, 77, 112, 0.5)',
  COLOR7 = 'rgb(12, 55, 88, 0.5)',
  COLOR8 = 'rgb(255, 136, 77, 0.5)',
  COLOR9 = 'rgb(217, 217, 217, 0.5)',
  COLOR10 = 'rgb(153, 0, 255, 0.5)',
  COLOR11 = 'rgb(122, 0, 204, 0.5)',
  COLOR12 = 'rgb(92, 0, 153, 0.5)',
  COLOR13 = 'rgb(0, 153, 0, 0.5)',
  COLOR14 = 'rgb(0, 255, 0, 0.5)',
  COLOR_RED1 = 'rgb(255, 0, 102, 0.5)',
  COLOR_RED2 = 'rgb(204, 0, 82, 0.5)',
}
