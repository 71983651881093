import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientRoutes } from '../models/enums/client-routes.enum';
import { isTokenValid } from '../utils/authorization.util';

interface AuthGuardProps {
  /** Input DOM element */
  children: JSX.Element;
  /** Set to true to redirect if not authorized. */
  redirectToLogin?: boolean;
}

export const AuthGuard: React.FC<AuthGuardProps> = (props: AuthGuardProps): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const navigate = useNavigate();

  const checkUserToken = (): void => {
    if (isTokenValid()) {
      setIsAuthenticated(true);
    } else {
      /** Redirect to login */
      if (props.redirectToLogin) {
        navigate(ClientRoutes.USER_LOGIN);
      }
      setIsAuthenticated(false);
    }
  };

  /** Check if user has a valid token and is authenticated */
  useEffect(() => {
    checkUserToken();
  }, [isAuthenticated]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <>{isAuthenticated ? props.children : null}</>;
};
