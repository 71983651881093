import { StockChartDto } from '../../modules/stock/models/dtos/stock-chart.dto';
import { StockDto } from '../models/dtos/stock.dto';
import { mwApiInstance } from './axios.middleware';

export const loadStock = async (symbol: string): Promise<StockDto> => {
  const { data } = await mwApiInstance.get(`/stock/${symbol}`);
  return data;
};

export const loadStockChart = async (symbol: string): Promise<StockChartDto> => {
  const { data } = await mwApiInstance.get(`/stock/${symbol}/chart`);
  return data;
};
