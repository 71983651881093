/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { PortfolioDto } from '../../../../modules/portfolio/models/portfolio.dto';
import { setPortfolio } from '../../../../redux-store/slices/portfolio.slice';
import { UserState, setIsUserLoggedIn } from '../../../../redux-store/slices/user.slice';
import { ReduxStore } from '../../../../redux-store/store';
import { ClientRoutes } from '../../../models/enums/client-routes.enum';
import { loadPortfolioStocks } from '../../../services/portfolio.service';
import { isTokenValid, logOut } from '../../../utils/authorization.util';
import { Button } from '../../controls/button/button';
import { StockSearch } from '../../controls/stock-search/stock-search';
import './navigation.scss';

export const Navigation: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector<ReduxStore, UserState>((state) => state.user);
  const portfolioStocks = useSelector<ReduxStore, any[]>((state) => state.portfolio.portfolioDtos);

  const [isNavOpen, setIsNavOpen] = useState(false);

  /** Check if user is authorized */
  useEffect(() => {
    if (isTokenValid()) dispatch(setIsUserLoggedIn(true));
    else dispatch(setIsUserLoggedIn(false));
  }, []);

  useEffect(() => {
    if (user.isAuthorized && !portfolioStocks.length) {
      loadUserPortfolioStocks();
    }
  }, [user.isAuthorized]);

  /** Close menu after path changed */
  useEffect(() => {
    setIsNavOpen(false);
  }, [window.location.pathname]);

  const loadUserPortfolioStocks = async (): Promise<void> => {
    const portfolio: PortfolioDto[] = await loadPortfolioStocks();
    dispatch(setPortfolio(portfolio));
  };

  const toogleNav = (): void => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className='bg-slate-900 fixed w-full z-20 top-0 left-0 border-b border-gray-600'>
      <div className='max-w-screen-2xl flex flex-nowrap items-center justify-between mx-auto p-3'>
        <Link to='./' className='flex items-center'>
          <img
            title='tradesyze-logo'
            alt='tradesyze-logo'
            src={process.env.PUBLIC_URL + '/logo.png'}
            width='40'
            height='40'
            className='mr-3'
          />
        </Link>

        <div className='flex grow md:mx-auto'>
          <StockSearch />
        </div>

        {/* Center Section */}
        <div className='flex md:order-1'>
          <button
            type='button'
            className='inline-flex items-center p-2 text-sm rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600'
            onClick={() => toogleNav()}
          >
            <span className='sr-only'>Open main menu</span>
            <svg className='w-6 h-6' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        </div>

        {/* Right Section */}
        <div className={classNames(isNavOpen ? '' : 'hidden', 'dropdown-menu w-full md:flex md:w-auto md:order-2')}>
          <ul className='flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 md:flex-row md:space-x-8 md:mt-0 bg-slate-900 border-0'>
            <li className='menu-item'>
              <Link to={ClientRoutes.INDICE_WORLD}>World Indizes</Link>
            </li>
            <li className='menu-item'>
              <Link to={ClientRoutes.SCREENER}>Screener</Link>
            </li>

            {/* Authorized Menu Items */}
            {user.isAuthorized ? (
              <>
                <li className='menu-item'>
                  <Link to={ClientRoutes.PORTFOLIO}>Portfolio</Link>
                </li>

                <Button onClick={() => logOut()}>Abmelden</Button>
              </>
            ) : (
              <Button onClick={() => navigate(ClientRoutes.USER_LOGIN)}>Anmelden</Button>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
