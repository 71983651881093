import React from 'react';
import { TableCell } from './table-cell';
import { TableRow } from './table-row';

interface TableBodyProps {
  children: React.ReactNode;
}

export const TableBody: React.FC<TableBodyProps> = (props: TableBodyProps) => {
  return (
    <tbody>
      {React.Children.toArray(props.children).length === 0 ? (
        <TableRow>
          <TableCell>No Data</TableCell>
        </TableRow>
      ) : (
        <>{props.children}</>
      )}
    </tbody>
  );
};
