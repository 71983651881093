import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../core/components/controls/button/button';
import { UserDto } from '../../../../core/models/dtos/user.dto';
import { ClientRoutes } from '../../../../core/models/enums/client-routes.enum';
import { createNewUser } from '../../../../core/services/user.service';
import { SnackbarState, showSnackbar } from '../../../../redux-store/slices/snackbar.slice';

export const UserRegistration: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const isValidEmail = (email: string): boolean => {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const isValidPassword = (password: string): boolean => {
    // Mindestens 6 Zeichen lang
    if (password.length < 6) return false;

    // Mindestens einen Großbuchstaben enthalten
    if (!/[A-Z]/.test(password)) return false;

    // Mindestens einen Kleinbuchstaben enthalten
    if (!/[a-z]/.test(password)) return false;

    // Mindestens eine Zahl enthalten
    if (!/\d/.test(password)) return false;

    // Mindestens ein Sonderzeichen enthalten
    if (!/[!@#$%^&*()_+{}\\[\]:;<>,.?~\\/-]/.test(password)) return false;

    // Alle Bedingungen erfüllt
    return true;
  };

  /** Handle registration of new user */
  const onClickRegister = async (): Promise<void> => {
    const newUser: UserDto = {
      email: username,
      password: password,
    };

    const newUserResponse = await createNewUser(newUser);

    if (newUserResponse) {
      dispatch(
        showSnackbar({
          message: 'Benutzer wurde erstellt',
          severety: 'success',
        } as SnackbarState)
      );

      navigate(ClientRoutes.USER_LOGIN);
    }
  };

  return (
    <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full'>
        <h1 className='text-center mb-4 font-extrabold leading-none tracking-tight text-2xl  md:text-3xl xl:text-4xl'>
          Jetzt <span className='text-secondary'>kostenlos registrieren</span>
        </h1>
      </div>

      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        <div className='space-y-6'>
          {/* Email Input */}
          <div>
            <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
              E-Mail
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                value={username}
                placeholder='E-Mail'
                required
                className='block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(e) => setUsername(e.target.value)}
              />
              {/* E-Mail adress pattern - error message */}
              {username !== '' && !isValidEmail(username) && <div className='negativ text-xs'>Ungültige E-Mail Adresse</div>}
            </div>
          </div>

          {/* Password Input */}
          <div>
            <div className='flex items-center justify-between'>
              <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                Password
              </label>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='password'
                type='password'
                value={password}
                placeholder='Password'
                required
                className='block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Password pattern - error message */}
              {password !== '' && !isValidPassword(password) && (
                <div className='negativ text-xs'>
                  Muss mind. 6 Zeichen, einen Großbuchstaben, einen kleinen Buchstaben, eine Zahl und ein Sonderzeichen enthalten
                </div>
              )}
            </div>
          </div>

          <Button type='submit' onClick={() => onClickRegister()} disabled={!isValidEmail(username) && !isValidPassword(password)}>
            Registrieren
          </Button>
        </div>
      </div>
    </div>
  );
};
