import React from 'react';
import { TitleContainer } from '../../../../core/components/controls/title-container/title-container';

export const BeatIndexSection: React.FC = () => {
  return (
    <section className='pb-8 mb-8 bg-white'>
      <div className='max-w-screen-xl mx-auto'>
        <TitleContainer
          title='Wie Du den Index schlagen kannst'
          content='Willst Du langfristig mit Aktien ein Vermögen aufbauen?<br>Dann brauchst Du eine Strategie, die zu Dir passt.'
        />
        <div className='relative w-full flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 mt-16 sm:mt-8 aos-init aos-animate'>
          <div className='max-w-[280px] xl:max-w-[363px] space-y-6 sm:space-y-3 text-center px-6'>
            <img src={`${process.env.PUBLIC_URL}/assets/img/finance-search.png`} alt='finance-search' className='max-w-[245px] mx-auto' />
            <h3 className='text-xl text-neutral-800 font-semibold'>Aktien finden</h3>
            <p className='text-md leading-relaxed mt-4 mb-4 text-gray-400'>
              Ganz einfach und schnell die besten Aktien aus einem weltweiten Aktienuniversum die richtigen Einzelaktien passend zu Deiner
              Strategie wählen.
            </p>
          </div>
          <div className='max-w-[280px] xl:max-w-[363px] space-y-6 sm:space-y-3 text-center px-6'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/finance-analysis.png`}
              alt='finance-analysis'
              className='max-w-[245px] mx-auto'
            />
            <h3 className='text-xl text-neutral-800 font-semibold'>Analysieren</h3>
            <p className='text-md leading-relaxed mt-4 mb-4 text-gray-400'>
              Aktien gemäß erfolgreicher Anlagestrategien analysieren um eine fundierte Anlageentscheidungen zu treffen.
            </p>
          </div>
          <div className='max-w-[280px] xl:max-w-[363px] space-y-6 sm:space-y-3 text-center px-6'>
            <img src={`${process.env.PUBLIC_URL}/assets/img/finance-buy.png`} alt='finance-buy' className='max-w-[245px] mx-auto' />
            <h3 className='text-xl text-neutral-800 font-semibold'>Kaufen</h3>
            <p className='text-md leading-relaxed mt-4 mb-4 text-gray-400'>
              Behalte den Überblick über die Entwicklung Deiner Aktien und entscheide dich für den kauf der besten Aktien.
            </p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/arrow.png`}
            alt='arrow-to-analyse'
            className='hidden lg:inline-block absolute top-16 left-64 xl:left-[20rem] w-24 xl:w-[9.5rem]'
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/arrow.png`}
            alt='arrow-to-buy'
            className='hidden lg:inline-block absolute top-16 right-64 xl:right-[20rem] w-24 xl:w-[9.5rem]'
          />
        </div>
      </div>
    </section>
  );
};
