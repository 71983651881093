import React, { useContext } from 'react';
import { ActiveTabContext } from '../../context/active-tab.context';
import './tab.scss';

interface TabProps {
  /** Display text */
  label: string;
  /** Tab value */
  value: string;
}

export const Tab: React.FC<TabProps> = (props: TabProps) => {
  const { activeTab, setActiveTab } = useContext(ActiveTabContext);

  const onClick = (value: any): void => {
    setActiveTab(value);
  };

  return (
    <button key={props.value} className={`tab ${activeTab === props.value ? 'active' : ''}`} onClick={() => onClick(props.value)}>
      {props.label}
    </button>
  );
};
