import axios from 'axios';
import { showSnackbar, SnackbarState } from '../../redux-store/slices/snackbar.slice';
import store from '../../redux-store/store';
import { LocalStorageTypes } from '../models/enums/local-storage.enum';
import { getItemLocalStorage } from './local-storage.service';

export const mwApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'x-fin-api': process.env.REACT_APP_X_FIN_API_KEY,
  },
});

/* -------------------------------------------------------------------------- */
/*                                Interceptors                                */
/* -------------------------------------------------------------------------- */

mwApiInstance.interceptors.request.use((config) => {
  const token = getItemLocalStorage(LocalStorageTypes.JWT_TOKEN);

  /** Add bearer token to request headers */
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

mwApiInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error) => {
    const errorMessage: string = error.response?.data.message || 'An error occured';

    // Dispatch the Redux action to show the Snackbar
    store.dispatch(
      showSnackbar({
        message: errorMessage,
        severety: 'error',
      } as SnackbarState)
    );

    return Promise.reject(error);
  }
);
