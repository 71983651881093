import React, { useEffect, useState } from 'react';
import { GrowthLabel } from '../../../core/components/controls/growth-label/growth-label';
import { SymbolLink } from '../../../core/components/controls/symbol-link/symbol-link';
import { TableBody } from '../../../core/components/controls/table/components/table-body';
import { TableCell } from '../../../core/components/controls/table/components/table-cell';
import { TableHead } from '../../../core/components/controls/table/components/table-head';
import { TableHeadCell } from '../../../core/components/controls/table/components/table-head-cell';
import { TableRow } from '../../../core/components/controls/table/components/table-row';
import { Table } from '../../../core/components/controls/table/table';
import { YahooQuoteModel } from '../../../core/models/interfaces/yahoo/yahoo-quote.interface';
import { loadScreener } from '../../../core/services/yahoo.service';
import { getCurrencySymbol } from '../../../core/utils/currency.util';
import { ScreenerTypes } from '../enum/screener-type.enum';

function createData(symbol: string, price: string, regularMarketChange: number, regularMarketChangePercent: number): ScreenerRows {
  return { symbol, price, regularMarketChange, regularMarketChangePercent };
}

interface ScreenerRows {
  symbol: string;
  price: string;
  regularMarketChange: number;
  regularMarketChangePercent: number;
}

interface ScreenerProps {
  /** Load screener from type */
  type: ScreenerTypes;
}

export const Screener: React.FC<ScreenerProps> = (props: ScreenerProps) => {
  const [title, setTitle] = useState<string>();
  const [rows, setRows] = useState<ScreenerRows[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Load screener data */
  useEffect(() => {
    const loadScreenerData = async (): Promise<void> => {
      setIsLoading(true);
      const screenerData = await loadScreener(props.type);
      setTitle(screenerData[0].title);

      const screenerRows: ScreenerRows[] = [];
      screenerData[0].quotes.forEach((quote: YahooQuoteModel) => {
        screenerRows.push(
          createData(
            quote.symbol,
            `${quote.regularMarketPrice?.toFixed(2)}${getCurrencySymbol(quote.currency)}`,
            Number(quote.regularMarketChange),
            Number(quote.regularMarketChangePercent)
          )
        );
      });

      setRows(screenerRows);
      setIsLoading(false);
    };

    loadScreenerData();
  }, [props.type]);

  return (
    <>
      <h1>{title}</h1>
      <Table loading={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell>Symbol</TableHeadCell>
            <TableHeadCell>Price</TableHeadCell>
            <TableHeadCell>Change</TableHeadCell>
            <TableHeadCell>Percent</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.symbol}>
              <TableCell>
                <SymbolLink symbol={row.symbol} />
              </TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>
                <GrowthLabel value={row.regularMarketChange} symbol={'$'} />
              </TableCell>
              <TableCell>
                <GrowthLabel value={row.regularMarketChangePercent} symbol={'%'} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
