import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../../core/components/controls/spinner/spinner';
import { NewsDto } from '../../../../core/models/dtos/news.dto';
import { StockDto } from '../../../../core/models/dtos/stock.dto';
import { loadSymbolNews } from '../../../../core/services/yahoo.service';
import { ReduxStore } from '../../../../redux-store/store';

export const StockNews: React.FC = () => {
  const stockDto = useSelector<ReduxStore, StockDto | undefined>((state) => state.stock.stockDto);
  const [news, setNews] = useState<NewsDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadNews = async (): Promise<void> => {
      setIsLoading(true);
      if (stockDto?.symbol) {
        const newsDtos: NewsDto[] = await loadSymbolNews(stockDto?.symbol);
        setNews(newsDtos);
        setIsLoading(false);
      }
    };

    loadNews();
  }, [stockDto]);

  return (
    <section className='stock-news my-4'>
      <div className='bg-white shadow-lg rounded-lg border border-gray-200'>
        <header className='px-5 border-b border-gray-100 flex items-center'>
          <h2 className='font-semibold text-gray-800'>Neuigkeiten</h2>
        </header>

        <div className='py-2'>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {news.length ? (
                <>
                  {news.map((value: NewsDto) => {
                    return (
                      <div key={`news-${value.uuid}`} className='mb-6 flex flex-wrap'>
                        <div className='mb-6 w-full shrink-0 grow-0 basis-auto px-3 md:mb-0 md:w-3/12'>
                          <div className='relative overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg shadow-black/20'>
                            <img src={value.thumbnail} className='w-full' alt='news-thumbnail' />
                            <a href={value.link} target='_blank' rel='noreferrer'>
                              <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]'></div>
                            </a>
                          </div>
                        </div>

                        <div className='mr-auto w-full shrink-0 grow-0 basis-auto px-3 md:mb-0 md:w-9/12'>
                          <h5 className='text-lg font-bold'>
                            <a href={value.link} target='_blank' rel='noreferrer'>
                              {value.title}
                            </a>
                          </h5>
                          <p className='mb-2 text-neutral-400'>
                            <small>
                              {value.publisher} <u>{moment(value.pubDate).format('HH:mm DD.MM.YYYY')}</u>
                            </small>
                          </p>
                          <p className='text-neutral-500'>{value.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className='px-5'>Leider haben wir zu diesem Thema nichts gefunden</div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
