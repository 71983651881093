import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StockDto } from '../../core/models/dtos/stock.dto';
import { StockChartModel } from '../../core/models/interfaces/yahoo/stock-chart.interface';
import { ScoreModel } from '../../modules/stock/models/score.model';

export type StockState = {
  stockDto?: StockDto;
  chart?: StockChartModel;
  dividendScore?: ScoreModel;
  hgiScore?: ScoreModel;
  levermannScore?: ScoreModel;
};

const initialState: StockState = {};

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setStockDto: (state: StockState, action: PayloadAction<StockDto>) => {
      state.stockDto = action.payload;
    },
    setStockChart: (state: StockState, action: PayloadAction<StockChartModel>) => {
      state.chart = action.payload;
    },
    setDividendScore: (state: StockState, action: PayloadAction<ScoreModel>) => {
      state.dividendScore = action.payload;
    },
    setHgiScore: (state: StockState, action: PayloadAction<ScoreModel>) => {
      state.hgiScore = action.payload;
    },
    setLevScore: (state: StockState, action: PayloadAction<ScoreModel>) => {
      state.levermannScore = action.payload;
    },
  },
});

export const { setStockDto, setStockChart, setDividendScore, setHgiScore, setLevScore } = stockSlice.actions;

export default stockSlice.reducer;
