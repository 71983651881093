import React, { useEffect, useState } from 'react';

interface CompanyAvatarProps {
  /** Image src url */
  imageUrl?: string;
  /** Default: auto */
  width?: string;
  /** Default: auto */
  height?: string;
}

const useImageWithFallback = (primarySrc: string, fallbackSrc: string): string => {
  const [src, setSrc] = useState(primarySrc);

  useEffect(() => {
    const img = new Image();
    img.src = primarySrc;
    img.onload = () => setSrc(primarySrc);
    img.onerror = () => setSrc(fallbackSrc);
  }, [primarySrc, fallbackSrc]);

  return src;
};

export const CompanyAvatar: React.FC<CompanyAvatarProps> = ({ imageUrl, width = 'auto', height = 'auto' }: CompanyAvatarProps) => {
  const placeholderUrl = 'https://via.placeholder.com/60';
  const clearBitLogoUrl = imageUrl ? `https://logo.clearbit.com/${imageUrl}` : placeholderUrl;
  const src = useImageWithFallback(clearBitLogoUrl, placeholderUrl);

  const imgTitle = imageUrl ? imageUrl.replace(/^https?:\/\//, '').replace(/^www\./, '') : '';

  return <img src={src} alt='company-avatar' title={imgTitle} width={width} height={height} />;
};
