import React from 'react';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';

export const ImprintModule: React.FC = () => {
  return (
    <SinglePageContainer>
      <h1>Angaben gemäß § 5 TMG</h1>
      <p>
        BS Software
        <br />
        St.-Amand-Straße 88a
        <br />
        56626 Andernach
        <br />
        <b>Vertreten durch:</b>
        <br />
        Bogdan Schreiber
      </p>
      <h1>Kontakt</h1>
      <p>
        Telefon: +49 176 6 29 29 358
        <br />
        E-Mail: info@tradesyze.de
      </p>
      <h1>Steuerliche Informationen</h1>
      <p>
        Finanzamt Mayen <br />
        Ust-IdNr: DE348884960
      </p>

      <hr className='h-px my-8 border-0 bg-gray-700'></hr>
      <h1>Inhaltlich Verantwortlich gemäß §55 Abs. 2 RStV:</h1>
      <a href='https://bssoftware.de'>BS Software</a>
      <h2>Haftung für Inhalte</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
        überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <h1>Haftung für Links</h1>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
        fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
        der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
        Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Links umgehend entfernen.
      </p>
    </SinglePageContainer>
  );
};
