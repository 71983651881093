import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { GrowthLabel } from '../../../../core/components/controls/growth-label/growth-label';
import { loadStockChart } from '../../../../core/services/stock.service';
import { getCurrencySymbol } from '../../../../core/utils/currency.util';
import { StockChartDto } from '../../models/dtos/stock-chart.dto';

interface StockChartProps {
  symbol: string | undefined;
}

export const StockChart: React.FC<StockChartProps> = (props: StockChartProps) => {
  const [stockChartData, setStockChartData] = useState<StockChartDto>();

  /* -------------------------------------------------------------------------- */
  /*                                    Hooks                                   */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const loadChart = async (): Promise<void> => {
      if (props.symbol) {
        const chartResponse = await loadStockChart(props.symbol);
        setStockChartData(chartResponse);
      }
    };

    loadChart();
  }, [props.symbol]);

  /* -------------------------------------------------------------------------- */
  /*                              Helper Functions                              */
  /* -------------------------------------------------------------------------- */

  const getDifferenceCurrentAndPreviousPrice = (): number => {
    if (stockChartData?.regularMarketPrice && stockChartData?.chartPreviousClose) {
      return stockChartData.regularMarketPrice - stockChartData.chartPreviousClose;
    }

    return 0;
  };

  const getPercentageDifference = (): number => {
    if (stockChartData?.regularMarketPrice && stockChartData.chartPreviousClose) {
      // ((Endwert - Anfangswert) / Anfangswert) * 100
      return ((stockChartData?.regularMarketPrice - stockChartData.chartPreviousClose) / stockChartData.chartPreviousClose) * 100;
    }

    return 0;
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Chart.js                                  */
  /* -------------------------------------------------------------------------- */

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  // Define Chart.js default settings
  Chart.defaults.font.family = '"Inter", sans-serif';
  Chart.defaults.font.weight = 500;
  Chart.defaults.color = 'rgb(148, 163, 184)';
  Chart.defaults.scale.grid.color = 'rgb(241, 245, 249)';
  Chart.defaults.plugins.tooltip.titleColor = 'rgb(30, 41, 59)';
  Chart.defaults.plugins.tooltip.bodyColor = 'rgb(30, 41, 59)';
  Chart.defaults.plugins.tooltip.backgroundColor = '#FFF';
  Chart.defaults.plugins.tooltip.borderWidth = 1;
  Chart.defaults.plugins.tooltip.borderColor = 'rgb(226, 232, 240)';
  Chart.defaults.plugins.tooltip.displayColors = false;
  Chart.defaults.plugins.tooltip.mode = 'nearest';
  Chart.defaults.plugins.tooltip.intersect = false;
  Chart.defaults.plugins.tooltip.position = 'nearest';
  Chart.defaults.plugins.tooltip.caretSize = 0;
  Chart.defaults.plugins.tooltip.caretPadding = 20;
  Chart.defaults.plugins.tooltip.cornerRadius = 4;
  Chart.defaults.plugins.tooltip.padding = 8;

  useEffect(() => {
    if (chartRef.current) {
      /** Destroy current chart to rerender */
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const myChartRef = chartRef.current.getContext('2d');
      if (myChartRef && stockChartData) {
        const chartDateLabels: Date[] = stockChartData.chart?.map((x) => x.date);
        const chartCloseValue: number[] = stockChartData.chart?.map((x) => x.close);

        chartInstanceRef.current = new Chart(myChartRef, {
          type: 'line',
          data: {
            labels: chartDateLabels, // X-Axis value
            datasets: [
              {
                data: chartCloseValue,
                fill: true,
                backgroundColor: 'rgba(59, 130, 246, 0.08)',
                borderColor: 'rgb(99, 102, 241)',
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
              },
            ],
          },
          options: {
            layout: {
              padding: 20,
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'month', // Group dates by months
                },
                grid: {
                  display: false, // Do not display vertical lines
                },
                ticks: {
                  callback: (value) => {
                    return moment(value).format('MMM');
                  },
                },
              },
              y: {
                ticks: {
                  callback: (value: string | number) => {
                    /** Zeige zwei Nachkommastellen für Zahlen die kleiner als 100 sind */
                    if (value && Number(value) < 100) {
                      return Number(value).toFixed(2);
                    }
                    return value;
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (context) => moment(context[0].label).format('DD.MM.YYYY'),
                  label: (context) => context.parsed.y.toFixed(2) + getCurrencySymbol(stockChartData.currency),
                },
              },
            },
            interaction: {
              intersect: false,
              mode: 'nearest',
            },
            maintainAspectRatio: false,
          },
        });
      }
    }
  }, [stockChartData]);

  return (
    <section>
      {/* <!-- Chart widget --> */}
      <div className='flex flex-col col-span-full xl:col-span-8 bg-white shadow-lg rounded-lg border border-gray-200'>
        <header className='px-5 border-b border-gray-100 flex items-center'>
          <h2 className='font-semibold text-gray-800'>Chart</h2>
        </header>
        <div className='px-5 py-1'>
          <div className='flex flex-wrap'>
            {/* <!-- Unique Visitors --> */}
            <div className='flex items-center py-2'>
              <div className='mr-5'>
                <div className='flex items-center'>
                  <div className='text-3xl font-bold text-gray-800 mr-2'>
                    {stockChartData?.regularMarketPrice?.toFixed(2)}
                    {getCurrencySymbol(stockChartData?.currency)}
                  </div>
                  <div className='flex text-sm font-medium'>
                    <GrowthLabel value={getDifferenceCurrentAndPreviousPrice()} symbol={stockChartData?.currency} useCalcSymbols={true} />
                    <div className='flex'>
                      <GrowthLabel value={getPercentageDifference()} symbol={'%'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-grow'>
          <canvas ref={chartRef} width='800' height='300'></canvas>
        </div>
      </div>
    </section>
  );
};
