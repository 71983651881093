import React, { useEffect, useState } from 'react';
import { TopScorerTable } from '../../../../core/components/building-blocks/topscorer-table/topscorer-table';

import { Tab, TabContext, TabPanel, Tabs } from '../../../../core/components/controls/tabs';
import { TopScorerDto } from '../../../../core/models/dtos/topscorer.dto';
import { TopScorerTypes } from '../../../../core/models/enums/topscorer.enum';
import { getTopScorer } from '../../../../core/services/topscorer.service';

export const CurrentTopScorer: React.FC = () => {
  const [tabValue, setTabValue] = useState(TopScorerTypes.DIV);
  const [topScorer, setTopScorer] = useState<TopScorerDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleChange = (newValue: TopScorerTypes): void => {
    setTabValue(newValue);
  };

  /** Load top scorer table */
  useEffect(() => {
    const loadTopScorer = async (): Promise<void> => {
      setLoading(true);
      const topScorerDtos: TopScorerDto[] = await getTopScorer(tabValue, 10);
      setTopScorer(topScorerDtos);
      setLoading(false);
    };

    loadTopScorer();
  }, [tabValue]);

  return (
    <TabContext value={tabValue}>
      <div className='md:flex md:flex-row justify-between'>
        <h2 className='font-bold text-2xl'>Aktuelle Topscorer</h2>

        <Tabs onChange={handleChange}>
          <Tab label='Dividenden' value={TopScorerTypes.DIV} />
          <Tab label='High-Growth-Investing' value={TopScorerTypes.HGI} />
          <Tab label='Levermann' value={TopScorerTypes.LEV} />
        </Tabs>
      </div>

      <TabPanel value={TopScorerTypes.DIV}>
        <TopScorerTable topScorer={topScorer} totalScore={15} isLoading={isLoading} />
      </TabPanel>
      <TabPanel value={TopScorerTypes.HGI}>
        <TopScorerTable topScorer={topScorer} totalScore={18} isLoading={isLoading} />
      </TabPanel>
      <TabPanel value={TopScorerTypes.LEV}>
        <TopScorerTable topScorer={topScorer} totalScore={13} isLoading={isLoading} />
      </TabPanel>
    </TabContext>
  );
};
