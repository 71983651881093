import React from 'react';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';
import { TitleContainer } from '../../core/components/controls/title-container/title-container';
import { BeatIndexSection } from './components/beat-index-section/beat-index-section';
import { CurrentTopScorer } from './components/current-topscorer/current-topscorer';
import { HeaderSection } from './components/header-section/header-section';
import { IndiceWidgets } from './components/indice-section/indice-section';
import { StatisticSection } from './components/statistic-section/statistic-section';
import { StrategiesSection } from './components/strategies-section/strategies-section';

export const HomeModule: React.FC = () => {
  return (
    <>
      <HeaderSection />
      <BeatIndexSection />
      <SinglePageContainer>
        <div className='max-w-screen-xl mx-auto'>
          <CurrentTopScorer />
        </div>
      </SinglePageContainer>

      <StatisticSection />

      <SinglePageContainer>
        <div className='max-w-screen-xl mx-auto'>
          <IndiceWidgets />

          <TitleContainer
            title='Eine sorgfältige Aktienanalyse'
            content='Die sorgfältige Analyse von Aktien ist von entscheidender Bedeutung, wenn es darum geht, erfolgreiche Investitionsentscheidungen zu treffen. Es gibt verschiedene Analysestrategien, die dabei helfen können, die potenzielle Rentabilität einer Aktie zu bewerten. Drei solcher Strategien sind die Dividenden-Strategie von Christian W. Röhl, das High-Growth-Investing und die Levermann Strategie. Im Folgenden werde ich erläutern, warum es wichtig ist, diese Strategien anzuwenden.
            <br><br>
            Die Dividenden-Strategie von Christian W. Röhl konzentriert sich auf Unternehmen, die regelmäßig Dividenden ausschütten. Eine solche Strategie kann für Investoren attraktiv sein, die ein stabiles Einkommen aus ihren Anlagen erzielen möchten. Durch die Analyse der Dividendenhistorie eines Unternehmens sowie seiner finanziellen Stabilität kann festgestellt werden, ob das Unternehmen in der Lage ist, Dividenden langfristig zu zahlen. Eine sorgfältige Bewertung der Dividendenrendite, des Ausschüttungsverhältnisses und anderer finanzieller Kennzahlen ist unerlässlich, um das Risiko von Investitionen zu minimieren und potenzielle Renditen zu maximieren.
            <br><br>
            Das High-Growth-Investing zielt darauf ab, Aktien von Unternehmen zu identifizieren, die ein hohes Wachstumspotenzial haben. Diese Strategie kann besonders für langfristig orientierte Investoren interessant sein, die bereit sind, höhere Risiken einzugehen, um potenziell höhere Renditen zu erzielen. Die Analyse von Faktoren wie Umsatzwachstum, Gewinnmargen, Marktposition und Innovation kann helfen, Unternehmen mit starkem Wachstumspotenzial zu identifizieren. Durch eine gründliche Untersuchung des Wettbewerbsumfelds und der Branche können potenzielle Risiken und Chancen erkannt werden, um fundierte Entscheidungen zu treffen.
            <br><br>
            Die Levermann Strategie, entwickelt von Susan Levermann, konzentriert sich auf die systematische Bewertung von Unternehmen anhand verschiedener finanzieller und operativer Kennzahlen. Diese Strategie verwendet einen Bewertungsansatz, bei dem jedem Unternehmen Punkte für bestimmte Kriterien vergeben werden. Zu den Kriterien gehören beispielsweise das KGV, die Eigenkapitalrendite, das Kursmomentum und die Analystenmeinungen. Die sorgfältige Anwendung dieser Strategie ermöglicht es Investoren, Aktien auszuwählen, die ein positives Gesamtbild aufweisen und potenziell gute Renditen erzielen können.
            <br><br>
            Es ist wichtig, diese Analysestrategien sorgfältig anzuwenden, da sie dazu beitragen können, das Risiko von Investitionen zu minimieren und gleichzeitig potenzielle Renditen zu maximieren. Durch eine gründliche Analyse können potenzielle Risiken und Chancen identifiziert werden, um fundierte Entscheidungen zu treffen. Die Analyse der Dividendenhistorie, des Wachstumspotenzials und verschiedener finanzieller Kennzahlen liefert wichtige Informationen über die finanzielle Gesundheit und Zukunftsaussichten eines Unternehmens. Investoren können so besser einschätzen'
          />

          <StrategiesSection />
        </div>
      </SinglePageContainer>
    </>
  );
};
